export function getTimeToRead(blocks) {
  if (blocks) {
    let wordsCounter = 0;
    blocks.forEach(({component, content}) => {
      if (component === 'text') {
        wordsCounter += content.split(/\s/g).length;
      }
    });

    const wordsPerMinute = 200;
    const timeToRead = Math.ceil(wordsCounter / wordsPerMinute);

    return timeToRead;
  }

  throw new Error('blocks is undefined');
}

export function getFeaturedArticles(data, category = 'blog', slice) {
  if (!data) return null;

  let storyblok;
  if (data.allStoryblokEntry) {
    storyblok = data.allStoryblokEntry.edges.map(({node}) => {
      const {name, slug, first_published_at} = node;
      const {_uid, cover, customDate} = JSON.parse(node.content);
      return {
        key: _uid,
        date: customDate ? new Date(customDate) : new Date(first_published_at),
        title: name,
        slug,
        cover,
        category: category,
      };
    });
  }

  const sortedArticles = storyblok.sort((a, b) => b.date - a.date);

  if (typeof slice === 'number') return sortedArticles.slice(0, slice);

  return sortedArticles;
}

export function lazyloadImage(img) {
  if (img.srcset === '') {
    if (typeof img.dataset.srcset !== 'undefined') {
      img.srcset = img.dataset.srcset;
    }
    if (typeof img.dataset.src !== 'undefined') {
      img.src = img.dataset.src;
    }
  }
}
