export const NavigationRoutes = [
  {to: '/visiter-amsterdam', txt: 'Visiter Amsterdam'},
  {to: '/vivre-aux-pays-bas', txt: 'Vivre aux Pays-Bas'},
  {to: '/blog', txt: 'Blog'},
  {to: '/about', txt: 'À propos'},
  {to: '/contact', txt: 'Contact'},
];

export const SocialMediasLinks = {
  instagram: 'https://www.instagram.com/clemencetaillez',
};
